import React from 'react'
import Landing from '../../components/Landing/Landing';

export default function LandingPage() {
  return (
    <div className="LandingPage">
      <Landing />
    </div>
  )
}
