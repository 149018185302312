import React from 'react'
import Oops from '../../components/Oops/Oops';

export default function PageNotFound() {
  return (
    <div className="PageNotFound">
      <Oops />
    </div>
  )
}
